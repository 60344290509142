body {
  font-family: "IBM Plex Sans", Arial, sans-serif;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--blue-gray-1);
  font-size: 100%;
}

a {
  color: #3770ef;
  text-decoration: none;
  font-size: 0.875em;
}
a:hover {
  text-decoration: underline;
}

/* porcentaje base, solo en el body */
p {
  font-size: 0.9em;
}
h1 {
  font-weight: 300;
  font-size: 2em;
  line-height: 1.5em;
}
h2 {
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.5em;
}
h3 {
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5em;
}
h4 {
  font-size: 1.25em;
  font-weight: 500;
  letter-spacing: 0.009em;
  line-height: 1.5em;
}
h5 {
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.016em;
  line-height: 1.5em;
}
.body1 {
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
}
.body2 {
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.25em;
}
.toast-title {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
  color: #ffffff;
}

.subtitle {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.063em;
}
caption {
  font-weight: 400;
  font-size: 0.563em;
  line-height: 1em;
  letter-spacing: 0.025em;
}
.table-header {
  display: flex;
  color: #5b717b;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.68em;
  line-height: 1.063em;
  /* or 155% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
#pie {
  font-size: 1.2em;
}
.epigrafes {
  font-size: 1.1em;
}

/* fin de zona común a todas las resoluciones */
@media screen and (min-width: 800px) {
  body {
    font-size: 100%;
    /* ampliamos los textos si mide más de 800px */
  }
}

/* fin de la zona para más de 800px de ancho de pantalla */
@media screen and (min-width: 1200px) {
  body {
    font-size: 120%;
    /* ampliamos más aún los textos si mide más de 1200px */
  }
}

/* fin de la zona para más de 1200px de ancho de pantalla */
/*-----------------------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
article,
.body1,
.body2,
.subtitle,
caption,
.toast-title,
i {
  font-style: normal;
  display: flex;
  align-items: center;
  color: #5b717b;
}

button {
  height: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.125em;
  letter-spacing: 0.047em;
  text-transform: uppercase;
}

div,
p,
i,
strong,
b,
h1,
h2,
h3,
h4,
h5,
h6,
section,
article,
button,
label,
select,
option,
input,
li {
  font-family: "IBM Plex Sans" !important;
}

* {
  box-sizing: border-box;
  font-family: "IBM Plex Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --white-base: #ffffff;
  --gray-90: #3e4345;
  --gray-20: #f1f3f4;
  --gray-30: #e3e5e5;
  --gray-40: #c6cacc;
  --gray-60: #919699;
  --gray-80: #5e6366;
  --dark-color: rgba(0, 0, 0, 0.87);
  --blue-gray-1: #f5f7f9;
  --blue-gray-2: #ebf2f5;
  --blue-gray-3: #cfdbe0;
  --blue-gray-4: #78909c;
  --blue-gray-5: #5b717b;
  --blue-gray-6: #45565e;
  --red-base: #f22c36;
  --red-smooth: #fee0e2;
  --fuchsia-base: #ed266e;
  --fuchsia-smooth: #fee0e9;
  --neon-blue-base: #5346f9;
  --neon-blue-smooth: #e5e3fe;
  --blue-base: #3770ef;
  --blue-smooth: #e2ebfd;
  --green-smooth: #e1f2e6;
  --yellow-base: #fac802;
  --yellow-smooth: #fef7df;
  --orange-base: #ff6131;
  --orange-smooth: #ffe8e1;
  --green-base: #2fbd55;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--blue-base) !important;
}

.MuiOutlinedInput-root.Mui-focused /* .MuiOutlinedInput-notchedOutline */ {
  border-color: var(--blue-base) !important;
}

@media screen and (min-width: 959px) {
  .DayPicker.DayPicker__horizontal {
    height: 350px;
  }
}

@media screen and (max-width: 959px) {
  .ag-theme-material .ag-paging-button-wrapper {
    margin: 0 2px !important;
  }
  .ag-theme-material .ag-paging-panel > * {
    margin: 0 6px !important;
  }
}

.DateRangePickerInput .DateInput {
  width: 45% !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: var(--blue-smooth) !important;
  border: 1px double var(--blue-smooth) !important;
  color: var(--blue-gray-5) !important;
}

.CalendarDay__selected {
  background: var(--blue-base) !important;
  border: 1px double var(--blue-base) !important;
}

.ag-header-cell-text {
  display: -webkit-box !important;
  white-space: normal !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  opacity: 0.5;
  border-radius: 50px;
  background-color: var(--blue-gray-4);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--blue-gray-5);
}

.DateInput_input {
  text-align: center;
  color: var(--blue-gray-5) !important;
  font-size: 0.9rem !important;
  font-weight: normal !important;
}

.DateInput_input__focused {
  border-bottom: 1px solid #3770ef !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #3770ef !important;
}

.CalendarDay__selected {
  border-radius: 20px;
}

.CalendarMonth_caption {
  text-transform: capitalize;
  font-size: 1.03rem !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

@media screen and (min-width: 769px) {
  .DateRangePicker_picker__directionLeft {
    left: -20px !important;
    top: 60px !important;
  }
  .CalendarMonth_table {
    border-collapse: inherit !important;
    border-spacing: 0px !important;
    border: none !important;
  }
  .CalendarDay__default {
    border: none !important;
  }
  .DateRangePickerInput__withBorder {
    width: 580px;
    border: none !important;
    border-bottom: 1px solid #dbdbdb !important;
  }
  .DayPicker__withBorder {
    box-shadow: none !important;
  }
  .DateInput_fang {
    display: none;
  }
}

#leftMenu li:hover {
  background-color: #e8e8e8 !important;
}

.MuiAvatar-colorDefault {
  background-color: var(--blue-gray-3) !important;
}

.intercom-launcher-frame,
.intercom-lightweight-app-launcher {
  bottom: 9px !important;
  right: 9px !important;
}

div[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

div[data-title]:after {
  content: attr(data-title);
  background-color: white;
  font-size: 11px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  letter-spacing: 0.4px;
  text-align: left;
  position: absolute;
  padding: 15px;
  bottom: -36px;
  left: 25%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 4px;
  color: var(--blue-gray-5);
  max-width: 250px;
}

div[data-title] {
  position: relative;
}

.MuiAutocomplete-paper {
  min-width: 300px !important;
}
